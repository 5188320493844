import { createContext, useContext } from 'react'

const RoleContext = createContext(null)

export const useRoleCTX = () => useContext(RoleContext)

export const RoleContextProvider = ({ children, taskEditPrivileges }) => {
  return (
    <RoleContext.Provider value={taskEditPrivileges}>
      {children}
    </RoleContext.Provider>
  )
}
