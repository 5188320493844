import { ControlIcon, ReadonlyCell } from './styled'
import ControlButton from 'ui/components/ControlButton'
import Icon from 'ui/components/Icon'
import { deleteTaskEstimate } from 'api/tasks'
import { safeQuery } from 'helpers/safeQuery'
import { useSelector } from 'react-redux'
import { currentUserSelector } from 'store/users'
import { useEstimateAutosaving } from './hooks'
import { useFormikMediator } from 'hooks/useFormikMediator'
import { TastEstimateExecutor } from './components/TaskEstimateExecutor'
import { TaskEstimateValue } from './components/TaskEstimateValue'
import { TaskEstimatePeriod } from './components/TaskEstimatePeriod'
import { useRoleCTX } from '../../../../context'

export function TaskEstimate({ index, executors }) {
  const { isAdminOrManager, currentUserCanEditEstimate } = useRoleCTX()

  const form = useFormikMediator()
  const formEstimate = useFormikMediator(`estimates[${index}]`)

  const estimate = formEstimate.get()
  const estimates = form.get('estimates')
  const currentUser = useSelector(currentUserSelector)
  const taskExecutors = form.get('executors')
  const selectedExecutorId = formEstimate.get('user_id')
  const selectedExecutor = taskExecutors.find(
    (executor) => executor.id == selectedExecutorId
  )
  const currentExecutor = selectedExecutorId === currentUser.id
  const autosaveContext = useEstimateAutosaving(index)

  // Редактировать может
  // 1.админ или менеджер,
  // 2.пользователь, который может добавить только свою оценку,
  // 3. любая новая оценка без пользователя
  return isAdminOrManager ||
    (currentUserCanEditEstimate && currentExecutor) ||
    !selectedExecutorId ? (
    <>
      <TastEstimateExecutor
        estimateIndex={index}
        executors={executors}
        autosaveContext={autosaveContext}
        isAdminOrManager={isAdminOrManager}
      />
      <TaskEstimateValue
        estimateIndex={index}
        autosaveContext={autosaveContext}
      />
      <TaskEstimatePeriod
        estimateIndex={index}
        autosaveContext={autosaveContext}
      />
      <ControlButton
        theme="grey"
        css="height:42px;width:30px;"
        onClick={async () => {
          let deleted = true
          if (estimate.id) {
            deleted = !!(await safeQuery(
              () => deleteTaskEstimate(estimate.id),
              {
                successMessage: 'Оценка успешно удалена',
              }
            ))
          }
          if (deleted) {
            form.set(
              'estimates',
              estimates.filter((_estimate, i) => i != index)
            )
          }
        }}
      >
        <ControlIcon>
          <Icon name="close" />
        </ControlIcon>
      </ControlButton>
    </>
  ) : (
    <>
      <ReadonlyCell>
        {selectedExecutor?.value ??
          (selectedExecutorId == currentUser.id ? currentUser.name : null)}
      </ReadonlyCell>
      <ReadonlyCell>{formEstimate.get('estimate')}</ReadonlyCell>
      <ReadonlyCell>
        {estimate.date_start}
        {' - '}
        {estimate.date_end}
      </ReadonlyCell>
      <span />
    </>
  )
}
