import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { projectInfoSelector } from 'store/projects'
import { currentUserSelector } from 'store/users'

const MANAGER_ID = 1

export const usePrevilegies = () => {
  const user = useSelector(currentUserSelector)
  const projectInfo = useSelector(projectInfoSelector)
  const [isManager, setIsManager] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    setIsManager(!!user?.is_admin)
    setIsAdmin(!!user?.is_admin)

    if (projectInfo?.users) {
      const projectUser = projectInfo?.users.filter((el) => el.id === user?.id)
      if (projectUser?.length > 0) {
        Array.isArray(projectUser[0]?.role) &&
          projectUser[0]?.role.forEach((i) => {
            if (i.id === MANAGER_ID) setIsManager(true)
          })
      }
    } else {
      setIsManager(false)
    }
  }, [projectInfo, user])

  return { isManager, isAdmin }
}

export const usePrevilegiesGlobal = () => {
  const user = useSelector(currentUserSelector)
  const [isManagerGlobal, setIsManagerGlobal] = useState(false)

  useEffect(() => {
    if (
      user?.is_admin ||
      user?.is_manager ||
      user?.projects?.some((proj) => proj.role.id === MANAGER_ID)
    ) {
      setIsManagerGlobal(true)
    }
  }, [user])

  return { isManagerGlobal }
}
