import styled from 'styled-components'
import Select from 'ui/components/forms/Select/'

export const DateFieldContainer = styled.div``

export const ExecutorSelect = styled(Select)`
  width: 100%;
  && {
    margin: 0;
  }
`

export const ControlIcon = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ReadonlyCell = styled.span`
  display: flex;
  min-height: 42px;
  align-items: center;
  padding: 8px 16px;
`
