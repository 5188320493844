import getFilterParams from 'helpers/getFilterParams'

export const getTaskListParams = (data) => {
  const params = {}
  const orderParams = {}
  const metaParams = {
    page: data.page,
    count: data.count,
  }

  const filterParams = getFilterParams(data, [
    'user_id',
    'component_id',
    'type_id',
    'priority_id',
  ])

  if (data.order_by && data.order_type) {
    orderParams['order[by]'] = data.order_by
    orderParams['order[type]'] = data.order_type.toUpperCase()
  }

  if (data.name) {
    params['filter[name]'] = data.name
  }

  if (data.without_epic) {
    params['filter[without_epic]'] = data.without_epic
  }

  if (data.is_supertask) {
    params['filter[is_supertask]'] = data.is_supertask
  }

  if (data?.start_dates) {
    const dates = JSON.parse(data?.start_dates)[0].split(',')
    params[`filter[date_start_from]`] = dates[0]
    params[`filter[date_start_to]`] = dates[1]
  }

  if (data?.end_dates) {
    const dates = JSON.parse(data?.end_dates)[0].split(',')
    params[`filter[date_end_from]`] = dates[0]
    params[`filter[date_end_to]`] = dates[1]
  }

  if (data?.deadline_dates) {
    const dates = JSON.parse(data?.deadline_dates)[0].split(',')
    params[`filter[deadline_from]`] = dates[0]
    params[`filter[deadline_to]`] = dates[1]
  }

  return {
    ...params,
    ...filterParams,
    ...metaParams,
    ...orderParams,
  }
}
