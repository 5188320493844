import styled from 'styled-components'
import Button from 'ui/components/buttons/Button'

export const TaskEstimatesHeader = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`

export const AddEstimateButtonStyled = styled(Button)`
  width: auto;
  margin: 0;
`
