import { format } from 'date-fns'
import { getMinutesFromString } from './getMinutesFromString'
import { formatToTime } from './formatToTime'

const commonSelectsDefaultMapper = ({ id, slug, name }, withId = false) => ({
  id: id || slug,
  value: withId ? `#${id} ${name}` : name,
  label: withId ? `#${id} ${name}` : name,
})
const commonSelectNormalizeItem = (item) => {
  item.id = item.id ?? item.slug
  item.value = item.value ?? item.label ?? item.id
  item.label = item.label ?? item.value ?? item.id
  return item
}
export const prepareCommonSelects = (data, withIdOrMapper = false) => {
  if (!data) {
    return data
  }
  if (Array.isArray(data)) {
    return data.map((item) => prepareCommonSelects(item, withIdOrMapper))
  } else {
    return typeof withIdOrMapper == 'boolean'
      ? commonSelectsDefaultMapper(data, withIdOrMapper)
      : commonSelectNormalizeItem(withIdOrMapper(data))
  }
}

export const prepareRoleList = (role) => {
  return role?.length > 0
    ? role.map(({ id, name }) => {
        return {
          id: id,
          value: name,
          label: name,
        }
      })
    : []
}

export const prepareStuff = (users) => {
  const preparedStuff = []

  users.forEach((item) => {
    const exsistingIndex = preparedStuff.findIndex(
      (user) => user.id === item.id
    )

    if (exsistingIndex >= 0) {
      preparedStuff[exsistingIndex].role.push(item.role)
    } else {
      preparedStuff.push({
        ...item,
        role: [item.role],
      })
    }
  })

  return preparedStuff
}

const prepareTaskEstimate = (estimate) => ({
  ...estimate,
  estimate: getMinutesFromString(estimate.estimate),
})

export function prepareTaskEstimates(estimates) {
  if (!Array.isArray(estimates)) {
    return prepareTaskEstimate(estimates)
  }
  return estimates.map(prepareTaskEstimate)
}

const TASKS_WITHOUT_ESTIMATES = ['Эпик']
export const prepareTaskValues = (values, type, stage, isAdminOrManager) => {
  const prepared = Object.fromEntries(
    Object.entries(values).map((entity) => {
      return entity.map((value) => {
        if (
          typeof value === 'object' &&
          value !== null &&
          !Array.isArray(value)
        ) {
          return value.id
        } else {
          return value
        }
      })
    })
  )
  //установка статуса задачи в бэклог если поля stage есть в сторе
  if (stage) {
    prepared.stage_id = 101
  }

  prepared.estimates = prepareTaskEstimates(prepared.estimates ?? [])

  if (!prepared.estimate_cost) {
    prepared.estimate_cost = 0
  } else {
    prepared.estimate_cost = getMinutesFromString(prepared.estimate_cost)
  }

  if ((type === 'create' && !prepared.estimate_cost) || !isAdminOrManager) {
    delete prepared.estimate_cost
  }
  const skipEstimates =
    !isAdminOrManager ||
    TASKS_WITHOUT_ESTIMATES.includes(values.task_type_id?.value)
  if (skipEstimates) {
    delete prepared.is_overtime
    delete prepared.estimates
  }
  if (type == 'edit') {
    // оценки редактируются и сохраняются самостоятельно не в составе всей формы
    delete prepared.estimates
  }
  return prepared
}

export const prepareUserIdList = (users) => {
  return users?.length > 0 ? users.map(({ id }) => id) : []
}

export const prepareUserList = (users) => {
  return users?.length > 0
    ? users.map(({ id, name, surname }) => {
        return {
          id: id,
          value: `${surname} ${name}`,
          label: `${surname} ${name} `,
        }
      })
    : []
}

export const prepareCurrentUserRoles = (staff, id, roleList) => {
  return staff
    .find((employee) => employee.id === id)
    .role.map((item) => {
      return roleList.find(({ id }) => id === item)
    })
    .reduce((previousValue, currentValue) => {
      previousValue.push(currentValue?.name)
      return previousValue
    }, [])
    .join(' ')
}

function prepareDate(date) {
  if (typeof date == 'string' && !date.match(/^\d\d\.\d\d\.\d\d\d\d$/))
    return format(new Date(date), 'dd.MM.yyyy')
  else return date
}
export const adaptTaskEstimates = (estimates) => {
  return estimates.map((estimate) => ({
    ...estimate,
    date_start: prepareDate(estimate.date_start),
    date_end: prepareDate(estimate.date_end),
    estimate:
      estimate.estimate == 0
        ? undefined
        : formatToTime(estimate.estimate).replace(/\s/g, ''),
  }))
}
