import { useFormikContext } from 'formik'
import {
  TaskEstimatesContainer,
  TaskEstimateScrollview,
  TaskEstimatesRoot,
} from './styled'
import { TaskEstimate } from './components/TaskEstimate/TaskEstimate'
import { AddEstimateButton } from './components/AddEstimateButton'
import { InputLabel } from 'ui/components/forms/Input/styled'
import { useRoleCTX } from '../../context'

export default function TaskEstimates({ executors }) {
  const formik = useFormikContext()
  const estimatesMeta = formik.getFieldMeta('estimates')
  const estimates = estimatesMeta.value ?? []
  const { canEditEstimate } = useRoleCTX()

  return (
    <TaskEstimatesRoot>
      {canEditEstimate && <AddEstimateButton estimates={estimates} />}

      {estimates.length > 0 && (
        <TaskEstimateScrollview className="task-estimate-scrollview">
          <TaskEstimatesContainer>
            <InputLabel>Ответственный</InputLabel>
            <InputLabel>Оценка</InputLabel>
            <InputLabel>Период выполнения</InputLabel>
            <span />
            {estimates.map((estimate, index) => (
              <TaskEstimate
                key={estimate.id ?? estimate.temp_id}
                executors={executors}
                index={index}
              />
            ))}
          </TaskEstimatesContainer>
        </TaskEstimateScrollview>
      )}
    </TaskEstimatesRoot>
  )
}
