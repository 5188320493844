import styled from 'styled-components'

export const TaskEstimatesRoot = styled.div`
  margin-bottom: 10px;
  margin-top: 20px;
`
export const TaskEstimateScrollview = styled.div`
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #abbed1;
    border-radius: 7px;
    border: 6px solid #fff;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border-left: 0;
    border-right: 0;
  }

  &::-webkit-scrollbar-thumb:vertical {
    border-top: 0;
    border-bottom: 0;
  }
`
export const TaskEstimatesContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(400px, auto) minmax(auto, 150px) auto auto;
  gap: 15px;
  margin-bottom: 10px;
  min-width: 500px;
  align-items: start;
  & > span {
    margin: 0;
  }
`
