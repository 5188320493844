import { randomId } from '../../../../../../../../../helpers/randomId'
import { format } from 'date-fns'

export const createEstimate = ({ user_id, estimate } = {}) => {
  return {
    temp_id: randomId(),
    user_id: user_id ?? null,
    estimate: estimate ?? '',
    date_start: format(new Date(), 'dd.MM.yyyy'),
    date_end: format(new Date(), 'dd.MM.yyyy'),
  }
}
