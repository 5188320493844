import { usePrevilegies } from '../../../../../../../hooks/usePrevilegies'
import { useSelector } from 'react-redux'
import { projectInfoSelector } from '../../../../../../../store/projects'
import { useFormikContext } from 'formik'
import { EstimateSwitch, Wrapper } from './styled'
import { taskModalSelector } from '../../../../../../../store/modals'
import { updateTask } from '../../../../../../../api/tasks'
import { notifyError, notifySuccess } from '../../../../../../components/Notify'
import { useState } from 'react'

export const EstimatesCheckboxes = () => {
  const { isManager, isAdmin } = usePrevilegies()
  const projectInfo = useSelector(projectInfoSelector)
  const canSetSupertask =
    isAdmin || (isManager && projectInfo?.perm_manager_create_supertask)
  const formik = useFormikContext()
  const { defaultTaskDetails, eventType } = useSelector(taskModalSelector)
  const [isLoading, setIsLoading] = useState(false)

  const onChange = async (superTaskValue) => {
    setIsLoading(true)
    updateTask(defaultTaskDetails?.id, {
      is_supertask: superTaskValue,
    })
      .then((res) => {
        if (res.errorMessage) {
          notifyError(
            res.data.message
              ? res.data.message
              : 'Вы не можете обновить эту задачу'
          )
          return
        }
        notifySuccess('Задача успешно изменена')
      })
      .then(() => {
        setIsLoading(false)
      })
  }

  return (
    <Wrapper>
      {canSetSupertask && (
        <EstimateSwitch
          label="Высокий приоритет"
          checked={!!formik.getFieldMeta('is_supertask').value}
          onChange={(e) => {
            formik.setFieldValue('is_overtime', 0)
            formik.setFieldValue('is_supertask', Number(e.target.checked))
            if (eventType === 'edit') {
              onChange(Number(e.target.checked))
            }
          }}
          disabled={isLoading}
        />
      )}
      <EstimateSwitch
        label="Овертайм"
        checked={!!formik.getFieldMeta('is_overtime').value}
        onChange={(e) => {
          formik.setFieldValue('is_supertask', 0)
          formik.setFieldValue('is_overtime', Number(e.target.checked))
        }}
      />
    </Wrapper>
  )
}
