import { InputLabel } from '../../../../../../../../components/forms/Input/styled'
import { TaskEstimatesHeader, AddEstimateButtonStyled } from './styled'
import { EstimatesCheckboxes } from '../../../EstimatesCheckboxes'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { currentUserSelector } from '../../../../../../../../../store/users'
import { createEstimate } from './helper'
import { useRoleCTX } from '../../../../context'

export const AddEstimateButton = ({ estimates }) => {
  const { isAdminOrManager } = useRoleCTX()
  const formik = useFormikContext()
  const currentUser = useSelector(currentUserSelector)
  const currentUserEstimate = estimates.find(
    (es) => es?.user_id === currentUser?.id
  )

  const adminAddEstimateHandler = () => {
    let newEstimates = []
    const selectedExecutors = formik.getFieldMeta('executors').value
    const hasDifference = estimates.length < selectedExecutors.length

    if (hasDifference) {
      const difference = selectedExecutors
        .filter(
          (estimate) => !estimates.map((ex) => ex.user_id).includes(estimate.id)
        )
        .map((selectedExecutor) =>
          createEstimate({
            user_id: selectedExecutor.id,
          })
        )
      newEstimates = [...estimates, ...difference]
    } else {
      newEstimates = [...estimates, createEstimate()]
    }

    formik.setFieldValue('estimates', newEstimates)
  }

  const userAddEstimateHandler = () => {
    if (currentUserEstimate) {
      return
    }

    formik.setFieldValue('estimates', [
      ...estimates,
      createEstimate({ user_id: currentUser.id }),
    ])
  }

  return (
    <>
      <InputLabel css="margin-bottom: 15px;">
        Оценки по ответственным
      </InputLabel>
      <TaskEstimatesHeader>
        <AddEstimateButtonStyled
          type="button"
          title="Добавить оценку"
          disabled={!isAdminOrManager && currentUserEstimate}
          onClick={
            isAdminOrManager ? adminAddEstimateHandler : userAddEstimateHandler
          }
        />
        {isAdminOrManager && <EstimatesCheckboxes />}
      </TaskEstimatesHeader>
    </>
  )
}
